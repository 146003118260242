import { mapMutations } from 'vuex';

export const ScrollToFaq = {
    methods: {
        scrollToFaq() {
            this.setCurrentTab('info');
        },

        ...mapMutations('mapStore', [
            'setCurrentTab',
        ]),
    },
};
