import okvedSearchStore from './modules/okvedSearchStore';
import { NodeValue, Tree } from 'tree';
import api from '../../../api/api';


export default {
    namespaced: true,

    state: {
        tree: {},
        groups: [],
        okveds: [],

    },

    modules: {
        okvedSearchStore,
    },

    getters: {
        getOkvedTree: state => state.tree,
    },

    mutations: {
        setOkvedTree: (state, payload) => {
            state.tree = new Tree(2);

            // 1 уровень - группа оквэд
            payload.groups.map(group => {
                let nodeValue = new NodeValue(group, 1);
                state.tree.addNodeByParentId(nodeValue, 0);
            });

            // 2 уровень - оквэд
            payload.okveds.map(okved =>
                payload.groups.filter(group => group.id === okved.id_group)
                    .map(group => {
                        let nodeValue = new NodeValue(okved, 2);
                        state.tree.addNodeByParentId(nodeValue, group.id);
                    }));
        },

        expandOkvedNode: (state, node) => state.tree.expandNode(node.level, node.id),

        chooseOkvedNode: (state, node) => state.tree.chooseNode(node.level, node.id),

        chooseOrCancelAllOkvedNodes: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        setSections(state, sections) {
            let groups = [];
            let okveds = [];

            sections.forEach(item => {
                if(item.id_group == 0) {
                    delete item.id_group;
                    groups.push(item);
                } else {
                    okveds.push(item);
                }
            });

            state.groups = groups;
            state.okveds = okveds;
        },
    },

    actions: {
        setOkveds: ({commit, state}) => {
            let groups = state.groups;
            let okveds = state.okveds;

            commit('setOkvedTree', { groups, okveds });
        },

        markChosenOkveds: ({commit, state}, okveds) => {
            if (state.tree.getAmountOfChosenLeafs() !== 0) {
                commit('chooseOrCancelAllOkvedNodes', false);
            }

            if (okveds.length === state.tree.getAmountOfChosenLeafs()) {
                commit('chooseOrCancelAllOkvedNodes', true);
            } else {
                for (let i = 0; i < okveds.length; i++) {
                    commit('chooseOkvedNode', {level: 2, id: okveds[i]});
                }
            }
        },
        chooseOkvedNode: ({commit}, node) => {
            commit('chooseOkvedNode', node);
        },

        async builtTree (context) {
            const sections = await api.fetchSections();
            context.commit('setSections', sections);
            await context.dispatch('setOkveds'); // !!!
        },
    },
};
