<template>
    <modal name="aboutHeader"
           styles="border: 3px solid #FFD800; border-radius: 10px;"
           :width="880" height="auto" :scrollable="true">
        <div class="mainBlock displayFlex flexDirectionColumn">
            <div @click="$modal.hide('aboutHeader')" class="closeImg">
                <img src="./img/closeIcon.png" alt="close" width="30px" height="28px"/>
            </div>
            <p><b>Export-Base</b></p>
            <p>Мы — организация, занимающаяся систематизацией и обновлением базы данных
                компаний в сети Интернет. Нашим основным инструментом является уникальный
                скрипт для сбора и актуализации информации о юридических лицах. Каждую
                неделю мы обновляем нашу базу данных и даем возможность использовать вам
                самую свежую информацию для Direct маркетинга и поиска партнёров по бизнесу.
            </p>
            <p>
                С нашим сервисом вы можете детально настроить базу, выбрав только ту информацию
                об организациях, которая вам нужна, не переплачивая за лишние данные.
            </p>
            <p>
                Продавайте уже сейчас. Воспользуйтесь
                <a href="https://export-base.ru/" target="_blank" rel="noreferrer noopener">нашей базой</a>,
                закажите холодный
                <a href="https://call.export-base.ru/" target="_blank" rel="noreferrer noopener">обзвон.</a>
            </p>
            <p><b>Плюсы работы с нами:</b></p>
            <ol>
                <li>Уникальность. Прежде всего, наша база – это уникальный информационный продукт.
                    Для получения данных мы используем такие источники как ФНС, Росстат,
                    электронные торговые площадки, информационно-аналитические системы,
                    наш собственный Call-центр и др. Благодаря такому широкому спектру
                    сбора информации наша база насчитывает более <b>9 829 400 компаний</b>,
                    что в разы превышает количество компаний в других базах.
                    Наша база учитывает только действующие компании.
                </li>
                <li>
                    Скорость. Сконфигурированная вами база будет доступна для загрузки сразу же
                    с момента поступления оплаты, при этом оплата возможна как по безналичному
                    расчёту (закрывающие документы высылаем по почте), так и путем электронных платежей.
                </li>
                <li>
                    Экономия. Наш конфигуратор невероятно гибкий, в нём вы можете настроить
                    практически каждую деталь вашей личной выгрузки. Так, вы не будете платить
                    за не нужные вам данные, а стоимость составляет всего от <b>1 копейки</b>
                    за контакт (при больших объемах)
                </li>
                <li>
                    Актуальность. Актуальность информации в базе поддерживается на уровне
                    <b>95% - 98%.</b> Она достигается путём еженедельного обновления базы и
                    постоянной работы алгоритмов по проверке валидности данных.
                    Наша база содержит только действующие компании.
                </li>
                <li>
                    Обновления. Вы можете обновить приобретенную базу через
                    <a href="https://export-base.ru/auth#auth" target="_blank"
                       rel="noreferrer noopener">личный кабинет.</a>
                    Это дает возможность актуализировать данные о компаниях в любой момент.
                </li>
            </ol>
            <div class="imageBlock displayFlex">
                <div class="imageBlock"></div>
                <div class="textBlock">
                    <p class="thesis1">Незаменимы для
                        <a href="https://export-base.ru/#email" target="_blank"
                           rel="noreferrer noopener">e-mail оповещений</a>,
                        информирования и привлечения клиентов
                    </p>
                    <p class="thesis2">Нужны для "холодных звонков"<br> и работы
                        <a href="https://call.export-base.ru/" target="_blank"
                           rel="noreferrer noopener">call-центра</a>
                    </p>
                    <p class="thesis3">Информативность для менеджеров<br> и отделов продаж.<br> Помогут скорректировать стратегию продаж</p>
                    <p class="thesis4">Самая полная база действующих компаний</p>
                </div>
            </div>
            <p class="italic leftMargin"><b>Нужен обзвон? Воспользуйтесь услугами нашего
                <a href="https://call.export-base.ru/" target="_blank"
                   rel="noreferrer noopener">Call-центра</a></b>
            </p>
            <p class="italic leftMargin"><b>Поддержите сервис, расскажите друзьям или вступите в группу
                <a href="https://vk.com/exportbase" target="_blank"
                   rel="noreferrer noopener">Вконтакте</a></b>
            </p>
        </div>
    </modal>
</template>

<script>
    export default {
        name: 'About',
    };
</script>

<style scoped>
    .mainBlock {
        margin: 1.3%;
        height: 100%;
        font-size: 14px;
        position: relative;
        color: #000000;
    }

    .mainBlock * {
        margin: 0.8% 0;
    }

    .displayFlex {
        display: flex;
    }

    .flexDirectionColumn {
        flex-direction: column;
    }

    a {
        color: #1B88C1;
    }

    a:hover {
        text-decoration: none;
    }

    .closeImg {
        position: absolute;
        left: 98.1%;
        bottom: 96.9%;
    }

    .imageBlock {
        background: url("./img/callCenterIcon.jpg") no-repeat 0 0;
        height: 310px;
    }

    .textBlock {
        margin: 3% 0 0 61%;
        z-index: 100;
        padding-left: 1%;
        position: relative;
    }

    .textBlock p {
        padding: 13px 9px;
        line-height: 100%;
    }

    .thesis1 {
        position: absolute;
        z-index: 20;
        background-color: white;
        width: 350px;
        top: 1.7%;
        left: 0;
    }

    .thesis2 {
        position: absolute;
        top: 22%;
        left: 0;
        z-index: 20;
        background-color: white;
        width: 350px;
    }

    .thesis3 {
        position: absolute;
        top: 39.5%;
        left: 0;
        z-index: 20;
        background-color: white;
        width: 350px;
    }

    .thesis4 {
        width: 350px;
        position: absolute;
        top: 65.8%;
        left: 0;
        z-index: 20;
        background-color: white;
    }

    .italic {
        font-style: italic;
    }

    ol {
        padding-left: 2.3%;
    }

    .leftMargin {
        margin-left: 3%;
    }
</style>
