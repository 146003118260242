<template>
  <header class="header">
    <About/>

    <nav class="headerContent">
      <a href="https://export-base.ru/" class="logo" target="_blank">
        <img width="300" height="62" class="imgLogo" src="./img/logo.png" alt="logo"/>
      </a>

      <div class="headerElementsBlocks">
        <button class="headerElements" @click="scrollToFaq">
          <span class="dashedBottom">Как это работает</span>
        </button>

        <div class="headerElements dropdown">
          <span class="dashedBottom">Базы данных</span>
          <div class="dropdownWrapper down">
            <div class="dropdownContent borderTriangle">
              <div class="dropdownBlock active">Сервисы выгрузки баз данных</div>
              <ul class="bodyLinks">
                <li class="linkWrapper"><a href="https://export-base.ru/" class="link" target="_blank">База
                  предприятий</a></li>
                <li class="linkWrapper"><a href="https://export-base.ru/new" class="link" target="_blank">База новых
                  компаний из ФНС</a></li>
                <li class="linkWrapper"><a href="https://export-base.ru/okved" class="link" target="_blank">База
                  ЕГРЮЛ</a></li>
                <li class="linkWrapper"><a href="https://export-base.ru/#coord" class="link" target="_blank">База по
                  районам городов</a></li>
                <li class="linkWrapper"><a href="https://export-base.ru/api_service" class="link" target="_blank">Доступ
                  по API</a></li>
                <li class="linkWrapper"><a href="https://export-base.ru/#key_search" class="link" target="_blank">Поиск
                  по словам</a></li>
              </ul>
              <div class="dropdownBlock dropdown">
                <span class="blockTitle">Сервисы актуализации баз</span>
                <img class="dropIcon" src="./img/arrow_right.svg" alt="">
                <div class="dropdownWrapper right">
                  <div class="dropdownContent">
                    <ul class="bodyLinks">
                      <li class="linkWrapper"><a href="https://export-base.ru/dop_uslugi/sbor_bazy_po_inn" class="link" target="_blank">
                        Актуализация ваших баз данных</a></li>
                      <li class="linkWrapper"><a href="https://export-base.ru/telegram_bot" class="link" target="_blank">
                        Telegram-бот ExportBase</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="dropdownBlock dropdown">
                <span class="blockTitle">Другие проекты ExportBase</span>
                <img class="dropIcon" src="./img/arrow_right.svg" alt="">
                <div class="dropdownWrapper right">
                  <div class="dropdownContent">
                    <ul class="bodyLinks">
                      <li class="linkWrapper"><a href="https://call.export-base.ru/" class="link" target="_blank">
                        Call-центр — обзвон клиентов</a></li>
                      <li class="linkWrapper"><a href="https://promo.export-base.ru/" class="link" target="_blank">
                        Рассылка писем (партнеры)</a></li>
                      <li class="linkWrapper"><a href="https://export-base.ru/export_analytics" class="link" target="_blank">
                        ExportAnalytics — статистика компаний</a></li>
                      <li class="linkWrapper"><a href="https://reddial.ru/" class="link"
                                                 target="_blank">Reddial — онлайн-база данных</a></li>
                      <li class="linkWrapper"><a href="https://versus-base.ru/" class="link" target="_blank">
                        Versus-base — каталог компаний</a></li>
                      <li class="linkWrapper"><a href="https://map.export-base.ru/" class="link activeLink" target="_blank">
                        Тепловая карта жилой застройки</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="headerElements" @click="$modal.show('aboutHeader')">
          <span class="dashedBottom">О нас</span>
        </button>
      </div>

      <a href="tel:+78007752912"
         class="number" title="Тех. поддержка работает с 09:00 до 21:00 по МСК. Звонок по России бесплатный">
        8 800 775-29-12
      </a>
    </nav>
  </header>
</template>

<script>
import About from "../Modals/About/AboutHeader";
import {ScrollToFaq} from "@/mixins/scrollToFaq";

export default {
  name: 'Header',

  components: {
    About
  },

  mixins: [ScrollToFaq],

};
</script>

<style scoped>
.logo {
  display: flex;
}

.header {
  background-color: #353535;
  height: 70px;
  display: flex;
  align-items: center;
  width: 100%;
}

.imgLogo {
  width: 220px;
  height: 50px;
}

.headerContent {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  color: white;
  position: relative;
  height: 60px;
  min-width: 1000px;
  max-width: 1160px;
  font-weight: bold;
}

.number {
  padding-right: 15px;
  margin-left: 50px;
  font-size: 20px;
  color: #fff;
}

.headerElementsBlocks {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  font-size: 18px;
}

.headerElements {
  padding: 10px 5px;
  background-color: #353535;
  font-weight: bold;
  cursor: pointer;
}

.dashedBottom {
  border-bottom: dashed 1px;
}

.dashedBottom:hover {
  border-color: #353535;
}

.dropdown {
  position: relative;
}

.dropdown:hover .dashedBottom {
  border-color: #353535;
}

.dropdown:hover > .dropdownWrapper {
  visibility: visible;
  opacity: 1;
}

.dropdownWrapper {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  z-index: 10;
}

.dropdownContent {
  position: relative;
  padding-top: 1px;
  border-radius: 5px;
  background-color: #424242;
  min-width: 320px;
}

.borderTriangle::after {
  content: '';
  position: absolute;
  top: -18px;
  right: 230px;
  border: 11px solid transparent;
  border-bottom: 12px solid #424242;
}

.dropdownBlock {
  background-color: #5e5e5e;
  padding: 15px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blockTitle {
  cursor: pointer;
  border-bottom: dashed 1px;
}

.dropdownBlock:hover .blockTitle {
  border-color: #5e5e5e;
}

.dropdownBlock:hover .dropIcon {
  transform: rotate(0.5turn);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.active {
  margin-top: 15px;
  margin-left: -5px;
  margin-right: -5px;
  border-radius: 5px;
}

.bodyLinks {
  font-size: 15px;
  padding: 15px 20px;
  list-style: none;
  font-style: normal;
}

.linkWrapper {
  margin-top: 5px;
}

.link {
  display: block;
  padding: 3px 5px;
}

.link:hover {
  color: #91ad2c;
}

.dropIcon {
  width: 16px;
  height: 16px;
}

.down {
  padding-top: 30px;
  left: -30px;
}

.right {
  padding-left: 10px;
  top: 0;
  right: -330px;
}

.activeLink {
  color: #91ad2c;
  background-color: #505050;
  pointer-events: none;
}

@media (max-width: 1230px) {
  .down {
    left: -80px;
  }
  .borderTriangle::after {
    right: 180px;
  }
}

@media (max-width: 1120px) {
  .down {
    left: -130px;
  }
  .borderTriangle::after {
    right: 130px;
  }
}

@media (max-width: 1020px) {
  .down {
    left: -160px;
  }
  .borderTriangle::after {
    right: 80px;
  }
}
</style>
