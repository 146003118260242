<template>
  <section class="mainMenuContent">
    <Feedback />

    <div class="configuratorBlock">
      <Tree @setCompany="setOkvedCompany"
            :resetNameOfOkved="resetNameOfOkved"/>

      <section class="group">
        <div class="block">
          <p class="header">Город, где планируется открытие:</p>
          <Selector @resetOkved="resetChosenOkved"/>
        </div>

        <div class="block">
          <p class="header">Выберите ваших потенциальных конкурентов:</p>
          <ButtonSelector width="260px"
                          :content="nameOfOkved ? nameOfOkved : 'Конфигуратор рубрик'"
                          class="buttonSelector"
                          @click.native="$modal.show('tree')"/>

          <CheckBox v-show="false" :chosen="isCheckboxChosen" @click.native="changeCheckboxState"
                    title="Показать расчет зоны действия конкурентов"/>
        </div>
      </section>

      <section class="group">
        <div class="block">
          <p class="header">Выберите место открытия вашей точки:</p>

          <div class="radioWrapper">
            <div class="radioElement">
              <input value="inputAddress" class="radio" id="radioInputAddress" type="radio" name="radio"
                     :checked="radioValue === 'inputAddress'" @change="changeSelectedRadio($event.target.value)">
              <label class="radioTitle" for="radioInputAddress">Выбрать адрес</label>
            </div>

            <div class="radioWrapper">
              <input value="putPoint" class="radio" id="radioPutPoint" type="radio" name="radio"
                     :checked="radioValue === 'putPoint'" @change="changeSelectedRadio($event.target.value)">
              <label class="radioTitle" for="radioPutPoint">Указать точку на карте</label>
            </div>
          </div>

          <SuggestInput />
        </div>

        <div class="block">
          <p class="header">Радиус охвата (м)</p>

          <div class="radiusInputBlock displayFlex">
            <input type="number" @change="changeRadius(+$event.target.value)"
                   title="Значение должно лежать в диапазоне от 100 до 6000м" step="100"
                   :value="radius"
                   class="heightInput inputNumber"/>
          </div>
        </div>
      </section>
    </div>

    <div class="resultBlock">
      <h2 class="title titleGroup">Расчет потенциала вашей<br> торговой точки:</h2>

      <div class="demand">
        Коэффициент спроса:
        <div class="demandInput">
        <input type="number" @input="handlerDemandFactor($event.target.value)" :value="getDemandFactor"
               class="heightInput coefInput" step="0.1"
               title="Значение должно лежать в пределах от 0 до 50%"/> %
        </div>
        <Hint text="Коэффициент спроса - это доля заинтересованных в вашем продукте людей" side="down-left"/>
      </div>

      <section class="resultSection">
        <img class="imgPreview" src="./img/user_group-2.png" alt="line" width="24" height="24"/>

        <div class="sectionContent">
          <p class="contentBlock">
            <span class="contentText">Людей проживает:</span>
            <b v-if="!isPreloadCalculating">
              ≈
              <template v-if="hideField">
                <span class="button" title="Нажми, чтобы открыть" @click="showPopup">{{ maskField }}</span>
              </template>
              <template v-else>
                {{ getResidents }}
              </template>
              чел.
            </b>
            <img v-else alt="preload calculating" class="preload" src="../img/preloader.gif">
          </p>
          <p class="contentBlock density">
            <span class="contentText">Плотность жителей:</span>
            <b v-if="!isPreloadCalculating">
              ≈
              <template v-if="hideField">
                <span class="button" title="Нажми, чтобы открыть" @click="showPopup">{{ maskField }}</span>
              </template>
              <template v-else>
                {{ getDensity }}
              </template>
              чел./км<span class="degree">2</span></b>
            <img v-else alt="preload calculating" class="preload" src="../img/preloader.gif">
          </p>
          <p v-show="isCheckboxChosen">Пересечение с конкурентами:<br><b>100 чел</b></p>
        </div>
        <div @click="showPopup">
          <Hint text="Нажмите, чтобы открыть" side="down-left" v-show="hideField" />
        </div>
      </section>

      <section class="resultSection">
        <img class="imgPreview" src="./img/fire.png" alt="line" width="20" height="26"/>
        <div class="sectionContent">
          <p>Потенциальные клиенты:
            <b v-if="!isPreloadCalculating">
              ≈
              <template v-if="hideField">
                <span class="button" title="Нажми, чтобы открыть" @click="showPopup">{{ maskField }}</span>
              </template>
              <template v-else>
                {{ getTraffic }}
              </template>
              чел</b>
            <img v-else alt="preload calculating" class="preload" src="../img/preloader.gif">
          </p>
        </div>
      </section>

      <template v-if="hideField">
        <div class="buttonOpenPopupWrapper">
          <button class="buttonOpenPopup" @click="showPopup">
            Показать данные
          </button>
        </div>
      </template>
      <template v-else>
        <p class="info" v-if="getIsAddress">
          <sup>*</sup>Данный расчет позволяет посчитать примерное число потенциальных клиентов вашей торговой точки на
          основе данных о жилой застройке в городе
        </p>
        <p class="info" v-else>
          Для проведения расчета выберите расположение точки продаж и предполагаемый радиус охвата
        </p>
      </template>
    </div>
  </section>
</template>

<script>
import Selector from '@/components/Selector/Selector';
import Tree from '@/components/Modals/Tree/Tree';
import ButtonSelector from 'buttonselector';
import CheckBox from 'checkbox';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import Hint from "@/components/Hint/Hint.vue";
import Feedback from "@/components/Modals/Feedback/Feedback.vue";
import SuggestInput from "@/components/MainMenu/SuggestInput/SuggestInput.vue";

const MASK = '░░░░░';

export default {
  name: 'MainMenuContent',
  components: {
    SuggestInput,
    Feedback,
    Hint,
    Selector,
    Tree,
    ButtonSelector,
    CheckBox,
  },

  data() {
    return {
      isRadiusValid: true,
      isDemandFactorValid: true,
      isCheckboxChosen: false,
      nameOfOkved: '',
      resetNameOfOkved: false,
    };
  },

  computed: {
    ...mapState(
        'mapStore', {
          residents: 'residents',
          demandFactor: 'demandFactor',
          isPreloadCalculating: 'isPreloadCalculating',
          radius: 'radius',
          radioValue: 'radioValue'
        }
    ),

    ...mapGetters(
        'mapStore', {
          cityName: 'cityName',
          getResidents: 'getResidents',
          getDensity: 'getDensity',
          getTraffic: 'getTraffic',
          getDemandFactor: 'getDemandFactor',
          getIsDisabledAddressInput: 'getIsDisabledAddressInput',
          getIsAddress: 'getIsAddress',
          hideField: 'hideField',
          maskField: 'maskField'
        }
    ),

    maskField() {
      return MASK;
    }
  },

  methods: {
    showPopup() {
      this.$modal.show('feedback');
      this.changeHasOpenPopup(true);
    },

    changeCheckboxState() {
      this.isCheckboxChosen = !this.isCheckboxChosen;
    },

    async setOkvedCompany(nameOfOkved) {
      this.nameOfOkved = nameOfOkved;

      await this.setCompany();
    },

    resetChosenOkved() {
      this.nameOfOkved = '';
      this.resetNameOfOkved = true;
      this.changeAddress('');
    },

    ...mapMutations(
        'mapStore', {
          setZoom: 'setZoom',
          changeAddress: 'changeAddress',
          changeHasOpenPopup: 'changeHasOpenPopup'
        }
    ),

    ...mapActions(
        'mapStore', {
          calculate: 'calculate',
          setCoordinates: 'setCoordinates',
          setCompany: 'setCompany',
          changeRadius: 'changeRadius',
          changeSelectedRadio: 'changeSelectedRadio',
          handlerDemandFactor: 'handlerDemandFactor',
        }
    ),
  },
};
</script>

<style scoped>
.buttonOpenPopupWrapper {
  margin-top: 15px;
  text-align: center;
}

.buttonOpenPopup {
  height: 45px;
  width: 300px;
  background-color: #18c139;
  color: white;
  font-size: 28px;
  border-radius: 5px;
  transition: background-color 0.15s ease-in-out 0s;
}

.buttonOpenPopup:hover {
  background-color: #15a932;
}

.radio {
  display: none;
}

.radioTitle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.radioTitle::before {
  content: "";
  align-self: flex-start;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 50%;
  margin-right: 7px;
}

.radio:checked + .radioTitle::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 3px;
  border-radius: 50%;
  background-color: #8ba608;
}

.radioTitle:hover::before, .radioTitle:hover::after {
  opacity: 0.7;
}

.radioWrapper {
  display: flex;
  margin-bottom: 5px;
}

.radioElement {
  margin-right: 10px;
}

.configuratorBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
}

.preload {
  height: 15px;
  width: 15px;
}

.resultSection {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.density {
  height: 28px;
  display: flex;
  align-items: center;
}

.contentText {
  margin-right: 5px;
}

.degree {
  vertical-align: super;
  font-size: 12px;
  margin-left: 2px;
}

.resultBlock {
  width: 45%;
}

.contentBlock {
  margin-top: 10px;
}

.mainMenuContent {
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 5px 5px;
  background: linear-gradient(75deg, #e5e3e3 50%, transparent 50%) #ccc;
  padding: 15px;
  height: 370px;
}

.inputNumber {
  width: 130px;
}

.titleGroup {
  margin-left: 35px;
}

.coefInput {
  width: 55px;
}

.imgPreview {
  align-self: center;
}

.block {
  margin-top: 15px;
}

.demand {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 35px;
}

.demandInput {
  margin-left: 5px;
}

#suggest {
  width: 400px;
}

.heightInput {
  height: 30px;
  font-size: 16px;
  padding: 5px;
}

.radiusInputBlock {
  display: flex;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.info {
  font-style: italic;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 40px;
}

.header {
  margin-bottom: 10px;
  font-weight: bold;
}

@media (max-width: 1140px)  {
  #suggest {
    width: 300px;
  }
}

@media (max-width: 800px)  {
  #suggest {
    width: 400px;
  }
}
</style>
