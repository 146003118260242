<template>
  <modal name="tree"
         styles="border: 3px solid #FFD800; border-radius: 10px;"
         width="700"
         height="auto"
         :scrollable="true"
         @before-close="getOkvedId(tree), setOkvedCompany(nameOfOkved)">

    <button class="close" @click="$modal.hide('tree')"></button>

    <div class="container">
      <div class="controlBlock">
        <SearchBar width="350px" height="28px" placeholder="Введите ключевое слово для поиска"
                   @handle="handleSearchText"
        />
        <button @click="$modal.hide('tree')">Выбрать</button>
        <button @click="resetOkvedSearchTree" v-show="isClearButtonVisible">Сбросить</button>
      </div>
      <TreeComponent :node="setRoot"
                     :highlight="true"
                     :expandable="true"
                     :search-text="searchText"
                     @expand-node="expandNode"
                     @choose-node="chooseNode"
      />
    </div>
  </modal>
</template>

<script>
import {TreeComponent} from 'tree';
import SearchBar from 'searchbar';
import {mapActions, mapMutations, mapGetters, mapState} from 'vuex';

export default {
  name: 'Tree',
  components: {
    TreeComponent,
    SearchBar,
  },

  data() {
    return {
      isClearButtonVisible: false,
      nameOfOkved: '',
    };
  },

  props: {
    resetNameOfOkved: Boolean,
  },

  watch: {
    resetNameOfOkved() {
      if (this.resetNameOfOkved) {
        this.nameOfOkved = '';
      }
    },
  },

  computed: {
    ...mapGetters('okvedStore', {
      okvedTree: 'getOkvedTree',
      filteredOkvedTree: 'getFilteredTree',
      searchText: 'getOkvedText',
    }),

    ...mapState(
        'okvedStore', {
          tree: 'tree',
        }
    ),

    setRoot() {
      return Object.keys(this.filteredOkvedTree).length === 0 ?
          this.okvedTree.root : this.filteredOkvedTree.root;
    },
  },
  methods: {
    ...mapMutations('okvedStore', {
      expandOkvedNode: 'expandOkvedNode',
      expandFilteredOkvedTreeNode: 'expandFilteredOkvedTreeNode',
      deleteFilteredOkvedTree: 'deleteFilteredOkvedTree',
    }),

    ...mapMutations('mapStore', {
      setSelectedSubSectionId: 'setSelectedSubSectionId',
    }),

    ...mapActions('okvedStore', {
      setOkvedTree: 'setOkveds',
      markChosenOkveds: 'markChosenOkveds',
      chooseOkvedNode: 'chooseOkvedNode',
      setFilteredOkvedTree: 'setFilteredOkvedTree',
      chooseFilteredOkvedTreeNode: 'chooseFilteredOkvedTreeNode',
      builtTree: 'builtTree',
    }),

    ...mapActions('mapStore', {
      setCompany: 'setCompany',
    }),

    handleSearchText(text) {
      this.setFilteredOkvedTree(text);
      if (Object.keys(this.filteredOkvedTree).length !== 0) {
        this.isClearButtonVisible = true;
      }
    },

    expandNode(node) {
      if (this.searchText === '') {
        this.expandOkvedNode(node);
      } else {
        this.expandFilteredOkvedTreeNode(node);
      }
    },
    chooseNode(node) {
      this.chooseOkvedNode(node);
      if (Object.keys(this.filteredOkvedTree).length !== 0) {
        this.chooseFilteredOkvedTreeNode();
      }

      let childrenOfTree = this.tree.root.children;
      let countOfRubric = 0;
      this.nameOfOkved = '';

      for (let i = 0; i < childrenOfTree.length; i++) {
        if (childrenOfTree[i].value.isChosen) {
          this.nameOfOkved = childrenOfTree[i].value.obj.name;
          countOfRubric += 1;
        }
      }

      if (countOfRubric > 1) {
        this.$modal.show('dialog', {
          text: 'К сожалению, можно выбрать только одну рубрику.',
        });
        this.chooseOkvedNode(node);
      }
    },

    resetOkvedSearchTree() {
      this.deleteFilteredOkvedTree();
      this.isClearButtonVisible = false;
    },

    getOkvedId(tree) {
      let okvedId = [];
      tree.root.children.forEach(treeChildren => {
        treeChildren.children.forEach(childrenOfTreeChildren => {
          if (childrenOfTreeChildren.value.isChosen) {
            okvedId.push(childrenOfTreeChildren.value.obj.id);
          }
        });
      });

      this.setSelectedSubSectionId(okvedId);
    },

    setOkvedCompany(nameOfOkved) {
      this.$emit('setCompany', nameOfOkved);
    },
  },
  created() {
    // создание непосредственно самого дерева.
    this.builtTree();
  },
};
</script>

<style scoped>

.close {
  background-image: url('./img/closeIcon.png');
  background-color: white;
  position: relative;
  top: 0;
  left: 95%;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.container {
  margin: 0 20px 20px 20px;
  padding: 5px;
  width: 700px;
}

.controlBlock {
  display: flex;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.controlBlock button {
  margin-left: 5%;
  padding: 1px 6px;
  cursor: pointer;
  background: #91ad2c;
  color: white;
  border: none;
}
</style>
