<template>
  <aside class="aside">
    <div class="background">
      <section class="asideBlock">
        <div class="head">
          <span class="okvedImg img"></span>
          <h3 class="title">Сервисы ExportBase</h3>
        </div>
        <ul class="bodyLinks services">
          <li class="linkWrapper"><a class="link" href="https://export-base.ru/" target="_blank">База предприятий</a></li>
          <li class="linkWrapper"><a class="link" href="https://export-base.ru/#okved" target="_blank">База ЕГРЮЛ</a></li>
          <li class="linkWrapper"><a class="link" href="https://export-base.ru/#reg_date" target="_blank">Новые компании</a></li>
          <li class="linkWrapper"><a class="link" href="https://export-base.ru/#key_search" target="_blank">Поиск по словам</a></li>
        </ul>
        <ul class="bodyLinks externalLinks">
          <li class="linkWrapper"><a href="https://export-base.ru/api_service" class="link" target="_blank">
            Доступ по API</a><img alt="" class="visualImg" src="./img/setting.png"></li>
          <li class="linkWrapper"><a href="https://export-base.ru/new#subscription" class="link" target="_blank">
            Подписка на новые компании</a></li>
        </ul>
      </section>

      <a href="https://call.export-base.ru/" class="asideBlock" target="_blank">
        <div class="head">
          <span class="callImg img"></span>
          <h3 class="title">Call-центр</h3>
        </div>
        <p class="body">
          Обзвон компаний, проведение опросов. Найдем клиентов для вашего бизнеса.
        </p>
      </a>

      <section class="asideBlock">
        <div class="head">
          <span class="actualizationLogoImg img"></span>
          <h3 class="title">Актуализация базы</h3>
        </div>
        <p class="body">
          Дополняем и обновляем вашу базу данных.
        </p>
        <ul class="bodyLinks">
          <li class="linkWrapper"><a href="https://export-base.ru/dop_uslugi/sbor_bazy_po_inn" class="link"
                                     target="_blank">Проверить ваш файл</a></li>
          <li class="linkWrapper"><a href="https://export-base.ru/dop_uslugi/sbor_bazy_po_inn" class="link"
                                     target="_blank">Проверка по списку ИНН</a></li>
          <li class="linkWrapper"><a href="https://export-base.ru/telegram_bot" class="link" target="_blank">
            Мониторинг блокировки счетов</a></li>
        </ul>
      </section>

      <section class="asideBlock">
        <div class="head">
          <span class="backLinkImg img"></span>
          <h3 class="title">Обратная связь</h3>
        </div>
        <p class="body">
          Рассмотрим ваши предложения - <a href="mailto:info@export-base.ru" class="commonLink">info@export-base.ru</a>
        </p>
      </section>

      <section
          class="asideBlock button"
          @click="showPopup">
        <div class="head">
          <span class="backLinkImg img"></span>
          <h3 class="title">Дарим 500 рублей за опрос</h3>
        </div>
        <p id="closingPopup" class="body">
          Пройдите опрос и получите 500 руб. на покупку баз данных в сервисе ExportBase
        </p>
      </section>
    </div>

    <iframe class="comments" title="Комментарии"
            src="https://yandex.ru/maps-reviews-widget/54633748342?comments">
    </iframe>
  </aside>
</template>

<script>

import {ScrollToFaq} from '@/mixins/scrollToFaq';
import {mapMutations} from "vuex";

export default {
  name: 'LeftMenu',
  mixins: [ScrollToFaq],

  methods: {
    ...mapMutations('mapStore', ['changeHasOpenPopup']),

    showPopup() {
      this.$modal.show('feedback');
      this.changeHasOpenPopup(true);
    }
  }
};

</script>

<style scoped>
.aside {
  border-radius: 5px;
  width: 27%;
  margin-right: 10px;
  height: 100%;
}

.background {
  background-color: #eee;
}

.head {
  display: flex;
}

.commonLink {
  color: #006fff;
  text-decoration: underline;
}

.commonLink:hover {
  text-decoration: none;
}

.body {
  margin-left: 45px;
}

.img {
  width: 33px;
  height: 33px;
  background-repeat: no-repeat;
  background-size: cover;
}

.services {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold;
  align-self: center;
}

.externalLinks {
  margin-top: 15px;
}

.okvedImg {
  background-image: url("./img/okved.png");
}

.actualizationLogoImg {
  background-image: url("./img/actualizationLogo.png");
}

.backLinkImg {
  background-image: url("./img/backLink.png");
}

.callImg {
  background-image: url("./img/call.png");
}

.bodyLinks {
  margin-left: 45px;
}

.link {
  padding: 2px 3px;
  cursor: pointer;
}

.link:hover {
  color: white;
  background-color: #91ad2c;
}

.linkWrapper {
  padding: 3px;
}

.visualImg {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.asideBlock {
  padding: 20px;
  border-bottom: solid 1px gray;
  border-radius: 5px;
  display: block;
}

.asideBlock:hover {
  background-color: #dbd7d9;
}

.asideBlock:hover .img {
  background-position: 0 -35px;
}

.comments {
  height: 730px;
  margin-top: 15px;
  padding: 0 5px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

@media (max-width: 1150px) {
  .comments {
    display: none;
  }
}

@media (max-width: 800px) {
  .aside {
    display: none;
  }
}

</style>
