import axios from "axios";

const API_PREFIX = '/api';

export default {
    async fetchUserCity() {
        const response = await axios.get(`${API_PREFIX}/userCity`);
        return response.data;
    },

    async fetchCities() {
        const response = await axios.get(`${API_PREFIX}/city`);
        return response.data;
    },

    async fetchPoints(selectedCityId) {
        const response = await axios.get(`${API_PREFIX}/point?id=${selectedCityId}`);
        return response.data;
    },

    async calculate(radius, mapCoordinates) {
        const response = await axios.get(`${API_PREFIX}/resident?radius=${radius}&lat=${mapCoordinates[0]}&lon=${mapCoordinates[1]}`);
        return response.data;
    },

    async fetchCoordinates(address) {
        const res = await axios.get(`${API_PREFIX}/coordinates?address=${address}`);
        return res.data.map(Number);
    },

    async fetchSections() {
        const response = await axios.get(`${API_PREFIX}/section`);
        return response.data;
    },

    async fetchCompany(radius, mapCoordinates, selectedCityId, selectedSubSectionId) {

        let data = {
            radius,
            lat: mapCoordinates[0],
            lon: mapCoordinates[1],
            selectedCityId,
            selectedSubSectionId,
        };

        const response = await axios.post(`${API_PREFIX}/company`, data);
        return response.data;
    },

    async fetchFeedback(phone, email, firstTime, secondTime) {
        await axios.post(`${API_PREFIX}/feedback`, {
            phone,
            email,
            firstTime,
            secondTime
        });
    },

    async fetchSuggest(query) {
        const res = await axios.get(`${API_PREFIX}/suggest?query=${query}`);

        return res.data;
    }
};
