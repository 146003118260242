import ValidSearchHelper from './ValidSearchHelper';

class ExecuteValidSearchHelper {
    // перечисление слов/фраз, которые запрещено искать
    static UNACCEPTABLE_SEARCH_WORDS = [' остальные нас. пункты ',
        'остальные населённые пункты ', ' остальные населенные пункты '];

    static execute (word, minLength) {
        let search = new ValidSearchHelper(this.UNACCEPTABLE_SEARCH_WORDS);
        return search.checkWordValidity(word, minLength);
    }
}

export default ExecuteValidSearchHelper;
