<template>
  <div id="selector">
    <div class="selectorWrapper">
      <ButtonSelector :content="cityName" @click.native="selectorChange" id="buttonSelector"/>
      <Hint text="Город был выбран на основе вашего ip-адреса." side="down"/>
    </div>
    <div class="select" v-show="isSelectorShow">
      <span v-for="city in getBigCities" :key="city.id"
            @click="getCityId(city.id)">
        {{ city.name }}
      </span>
      <hr class="separator">
      <span v-for="city in getCommonCities" :key="city.id"
            @click="getCityId(city.id)">
        {{ city.name }}
    </span>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
import ButtonSelector from 'buttonselector';
import Hint from "@/components/Hint/Hint.vue";

export default {
  name: 'Selector',

  data() {
    return {
      isSelectorShow: false,
    };
  },

  components: {
    Hint,
    ButtonSelector,
  },

  computed: {
    ...mapState(
        'mapStore', {
          cities: 'cities',
        },
    ),

    ...mapGetters(
        'mapStore', {
          cityName: 'cityName',
          getHeatMapViewGroup: 'getHeatMapViewGroup',
          getBigCities: 'getBigCities',
          getCommonCities: 'getCommonCities'
        },
    ),
  },

  methods: {
    ...mapMutations(
        'mapStore', {
          setSelectedCityId: 'setSelectedCityId',
          setMapCoordinates: 'setMapCoordinates',
          setZoom: 'setZoom',
          resetResults: 'resetResults',
          setInitialValuesMap: 'setInitialValuesMap'
        }
    ),

    ...mapActions(
        'mapStore', {
          setPoints: 'setPoints',
        }
    ),

    ...mapActions(
        'okvedStore', {
          markChosenOkveds: 'markChosenOkveds',
        }
    ),

    async getCityId(id) {
      this.resetResults();
      this.resetOkved();
      this.selectorChange();
      this.setSelectedCityId(id);
      this.setMapCoordinates();
      await this.setPoints();
      this.setInitialValuesMap(this.getHeatMapViewGroup);
    },

    selectorChange() {
      this.isSelectorShow = !this.isSelectorShow;
    },

    resetOkved() {
      this.markChosenOkveds(0);
      this.$emit('resetOkved');
    },

    outsideEventListener(event) {
      const buttonSelector = document.getElementById('buttonSelector');
      if (event.target === buttonSelector || buttonSelector.contains(event.target)) {
        return;
      }
      this.isSelectorShow = false;
    },
  },

  watch: {
    isSelectorShow() {
      if (this.isSelectorShow) {
        document.addEventListener('click', this.outsideEventListener);
      } else {
        document.removeEventListener('click', this.outsideEventListener);
      }
    },
  },
};
</script>

<style scoped>
.separator {
  width: 100%;
  margin: 5px auto;
  padding: 1px 0;
  background-color: gray;
}
#selector {
  display: flex;
  flex-direction: column;
  position: relative;
}

.selectorWrapper {
  display: flex;
}

.select {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  z-index: 15;
  width: 260px;
  height: 300px;
  overflow-y: scroll;
  top: 100%;
  padding: 0 5px;
}

.select span {
  margin: 1% 0;
}

.select span:hover {
  background-color: #91AD2C;
  color: white;
  cursor: pointer;
}
</style>
