import ExecuteValidSearchHelper from './../../../../helpers/ExecuteValidSearchHelper';

export default {
    state: {
        text: '',
        filteredTree: {},
    },

    getters: {
        getOkvedText: state => state.text,
        getFilteredTree: state => state.filteredTree,
    },

    mutations: {
        setFilteredOkvedTree: (state, payload) => {
            let minTextSize = 3;
            if (ExecuteValidSearchHelper.execute(payload.text, minTextSize)) {
                state.text = payload.text;
                state.filteredTree = payload.tree.filterTreeByNodeName(payload.text, minTextSize);
            } else {
                state.text = '';
                state.filteredTree = {};
            }
        },
        expandFilteredOkvedTreeNode: (state, node) => {
            state.filteredTree.expandNode(node.level, node.id);
        },
        chooseFilteredOkvedTreeNode: (state, tree) => {
            state.filteredTree.copyStateOfChoice(state.filteredTree.getRoot(), tree);
        },
        deleteFilteredOkvedTree: state => {
            state.text = '';
            state.filteredTree = {};
        },
    },

    actions: {
        setFilteredOkvedTree: ({commit, rootGetters}, text) => {
            let payload = {
                tree: rootGetters['okvedStore/getOkvedTree'],
                text
            };
            commit('setFilteredOkvedTree', payload);
        },
        chooseFilteredOkvedTreeNode: ({commit, rootGetters}) => {
            let tree = rootGetters['okvedStore/getOkvedTree'];
            commit('chooseFilteredOkvedTreeNode', tree);
        },
    },
};
