
export default function initializeStorage() {
    if (!localStorage['numberRecalculations']) {
        localStorage.setItem('numberRecalculations', '0');
    }

    if (!localStorage['hasOpenPopup']) {
        localStorage.setItem('hasOpenPopup', '0');
    }
}
