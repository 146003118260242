<template>
  <section class="aboutHeatMap">
    <div class="aboutMap">
      <div class="aboutMapContent">
        <p><b>Тепловая карта ExportBase &mdash; вашему Бизнесу.</b></p>
        <p>Тепловая карта предназначена для нахождения наиболее выгодных мест для размещения
          Вашего бизнеса и дает возможность найти точки с наибольшим потоком людей.</p>
        <p>Для того, чтобы воспользоваться картой, Вам следует выбрать интересующий Вас
          город и ввести в соответствующее поле адрес. После чего сервис рассчитает трафик
          по базовым значениям радиуса и коэффициента спроса, которые Вы можете гибко настроить.</p>
        <p>Радиус (от 100 до 6000м) позволяет изменять размер области на карте относительно выбранного адреса.
          <br>Коэффициент спроса (от 0 до 50%) поможет указать потенциальную величину спроса на Ваш продукт,
          соответствующую выбранному месту в вашем городе.</p>
        <p>В итоге Вы получите примерное количество жителей в указанном вами радиусе и потенциальный трафик.</p>
      </div>
      <div class="aboutMapContent">
        <p><b>Основы расчета тепловой карты.</b></p>
        <p>Тепловая карта делает расчет приблизительного количества жителей в указанном радиусе на основе
          этажности и количества жилых зданий, попадающих в выборку.</p>
        <p>Все данные о GPS-координатах жилых зданий и этажности, на
          основании которых производились расчеты, взяты из открытых
          источников, включая <a href="https://dom.mingkh.ru/" target="_blank" class="link">Дом.Минжкх</a>.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutHeatMap',
};
</script>

<style scoped>
.aboutHeatMap {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  border-radius: 5px;
  box-shadow: 0 0 1px #666;
  margin-top: 15px;
}

.aboutMap {
  margin: 0 1% 1% 1%;
}

.aboutMapContent {
  margin: 2.5% 0;
}

.aboutMapContent p {
  margin: 1% 1%;
}

.link {
  color: #338CC3;
  text-decoration: none;
  border-bottom: #338CC3 solid 1px;
  cursor: pointer;
}
</style>
