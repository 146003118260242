const LOCALE = 'ru-RU';
const ACCURACY = 1000;

export default function formatNumber(val) {
    if (val < 1000) {
        return val;
    }

    if (val < 10_000) {
        const number = new Intl.NumberFormat(LOCALE, {
            maximumFractionDigits: 1
        }).format((val / ACCURACY));
        return `${number} тыс`;
    }

    const number = new Intl.NumberFormat(LOCALE, {
        maximumFractionDigits: 0
    }).format((val / ACCURACY));
    return `${number} тыс`;
}
