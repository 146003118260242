import { render, staticRenderFns } from "./MainBlock.vue?vue&type=template&id=1ef759ff&scoped=true&"
import script from "./MainBlock.vue?vue&type=script&lang=js&"
export * from "./MainBlock.vue?vue&type=script&lang=js&"
import style0 from "./MainBlock.vue?vue&type=style&index=0&id=1ef759ff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef759ff",
  null
  
)

export default component.exports