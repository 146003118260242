<template>
  <div class="infoWrapper">
    <div class="info">
      <p class="titleList">Правовые основания</p>
      <p>
        Обращаем внимание, что мы не располагаем никакой информацией о жителях города как о физических лицах. Все карты
        построены на основе открытых источников и опираются на такие данные о жилой застройке, как, например, этажность
        дома и количество квартир в нем.
      </p>
    </div>

    <div class="info">
      <p class="titleList">Предлагаем приобрести контактные и юридические данные компаний России:</p>
      <ul class="bodyLinks">
        <li class="linkWrapper"><a href="https://export-base.ru/new" class="link" target="_blank">Недавно
          зарегистрированые компании</a></li>
        <li class="linkWrapper"><a href="https://export-base.ru/okved" class="link" target="_blank">База ЕГРЮЛ</a></li>
      </ul>
      <p>Карта собрана на основе открытых данных.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Info',
};
</script>

<style scoped>
.bodyLinks {
  margin: 10px 0;
  padding-left: 20px;
}

.linkWrapper {
  padding: 5px 2px;
}

.link {
  padding: 5px 2px;
  text-decoration: underline;
}

.link:hover {
  text-decoration: none;
}

.titleList {
  font-weight: bold;
  margin-bottom: 10px;
}

.info {
  margin-top: 15px;
  padding: 20px;
  border-radius: 5px;
  background-color: #eeeeee;
}

</style>
