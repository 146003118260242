<template>
  <div id="map">
    <div class="legend">
      <div class="legendWrapper">
        <span class="legendHead">Плотность жителей</span>
        <div class="legendBody"></div>
        <div class="legendFooter">
          <span>низкая</span>
          <span>высокая</span>
        </div>
      </div>
    </div>
    <img src="../img/preloader.gif" alt="preloader"
         width="75" height="75" :style="{ display: isPreload? 'block' : 'none' }">
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: 'Map',
  props: {
    isPreload: Boolean,
    mapCoordinates: Array,
    minMapZoom: Number,
    currentMapZoom: Number,
    radioValue: String,
    pointRadius: Number,
    intensityMidpoint: Number,
    points: Array,
    radius: Number,
    company: Array
  },

  watch: {
    radioValue() {
      if (this.radioValue === 'putPoint') {
        this.myMap.events.add('click', async (e) => {
          const coords = e.get('coords');
          this.changeAddress(coords.map(c => c.toFixed(7)).join(', '));
          this.radiusCircle.geometry.setCoordinates(coords);
          this.changeCoordinates(coords);
          await this.calculate();
        });
      } else {
        this.myMap.events.group().events.types.click = undefined;
      }
    },

    intensityMidpoint() {
      this.heatmap.options.set('intensityOfMidpoint', this.intensityMidpoint);
    },

    pointRadius() {
      this.heatmap.options.set('radius', this.pointRadius);
    },

    minMapZoom() {
      this.myMap.options.set('minZoom', this.minMapZoom);
      this.myMap.setZoom(this.minMapZoom);
    },

    currentMapZoom() {
      this.myMap.setZoom(this.currentMapZoom);
    },

    mapCoordinates() {
      this.myMap.setCenter(this.mapCoordinates, this.currentMapZoom);

      if (this.AddressPlacemark) {
        this.myMap.geoObjects.remove(this.AddressPlacemark);
      }

      if (this.address) {
        this.radiusCircle.geometry.setCoordinates(this.mapCoordinates);
        this.setAddressPlacemark(this.mapCoordinates);
        this.myMap.geoObjects.add(this.AddressPlacemark);
      }
    },

    radius() {
      this.radiusCircle.geometry.setRadius(this.radius);
    },

    company() {
      this.companyClusterer.removeAll();
      this.companyClusterer.add(this.companyConversion(this.company));
      this.myMap.geoObjects.add(this.companyClusterer);
    },

    points() {
      let pointsToHeatmap = this.pointsConversion(this.points);
      this.heatmap.setData(pointsToHeatmap);
    }
  },

  methods: {
    ...mapActions('mapStore', ['calculate']),

    ...mapMutations('mapStore', [
      'increaseZoom',
      'increasePointRadius',
      'increaseIntensityMidpoint',
      'decreaseZoom',
      'decreasePointRadius',
      'decreaseIntensityMidpoint',
      'changeCoordinates',
      'changeAddress'
    ]),

    pointsConversion(pointsFromDb) {
      let pointsToHeatMap = {
        type: "FeatureCollection",
        features: []
      };
      for (let i = 0; i < pointsFromDb.length; i++) {
        pointsToHeatMap.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [Number(pointsFromDb[i].lat), Number(pointsFromDb[i].lon)]
          },
          properties: {
            weight: Number(pointsFromDb[i].weight)
          }
        });
      }
      return pointsToHeatMap;
    },

    companyConversion(companyFromDb) {
      let companyToMap = [];

      for (let i = 0; i < companyFromDb.length; i++) {
        companyToMap[i] = new window.ymaps.GeoObject({
          geometry: {
            type: "Point",
            coordinates: [Number(companyFromDb[i].lat),
              Number(companyFromDb[i].lon)]
          },
          properties: {
            balloonContentHeader: `${companyFromDb[i].name}`,
            clusterCaption: `<strong> ${companyFromDb[i].name} </strong>`
          }
        });
      }
      return companyToMap;
    },

    setAddressPlacemark(coordinates) {
      this.AddressPlacemark = new window.ymaps.Placemark([coordinates[0], coordinates[1]], {
        balloonContentBody: this.address,
      }, {
        preset: 'islands#violetCircleDotIcon',
      });
    }
  },

  computed: {
    ...mapState('mapStore', ['address'])
  },

  created() {
    window.ymaps.ready(["Heatmap"]).then(() => {

      this.myMap = new window.ymaps.Map("map", {
        center: [55.7540471, 37.620405],
        zoom: 12,
        controls: []
      }, {
        minZoom: 12,
        maxZoom: 14
      });

      this.myMap.controls.add('zoomControl', {size: 'small'});

      this.myMap.events.add('boundschange', () => {
        const zoom = this.myMap.getZoom();

        if (zoom > this.currentMapZoom) {
          this.increaseZoom();
          this.increasePointRadius();
          this.increaseIntensityMidpoint();
        }
        if (zoom < this.currentMapZoom) {
          this.decreaseZoom();
          this.decreasePointRadius();
          this.decreaseIntensityMidpoint();
        }
      });

      this.myMap.behaviors.disable(['scrollZoom', 'multiTouch']);

      this.heatmap = new window.ymaps.Heatmap([], {
        radius: this.pointRadius,
        intensityOfMidpoint: this.intensityMidpoint,
        dissipating: true,
        gradient: {
          0.1: 'rgba(0, 255, 0, 0.5)',
          0.2: 'rgba(173, 255, 47, 0.5)',
          0.4: 'rgba(255, 255, 0, 0.8)',
          0.6: 'rgba(255, 165, 0, 0.8)',
          0.8: 'rgba(234, 72, 58, 0.8)',
          1: 'rgba(162, 36, 25, 0.8)'
        }
      });

      this.heatmap.setMap(this.myMap);

      this.radiusCircle = new window.ymaps.Circle([], {}, {
        fillColor: "rgba(219,112,147,0.1)",
        strokeColor: "rgb(153,0,102)",
        strokeOpacity: 0.7,
        strokeWidth: 1
      });

      this.myMap.geoObjects.add(this.radiusCircle);

      this.radiusCircle.geometry.setRadius(this.radius);

      this.companyClusterer = new window.ymaps.Clusterer({
        gridSize: 128,
      });

      this.$emit('mapIsReady');
    });
  }
}

</script>

<style scoped>
#map {
  width: 100%;
  height: 740px;
  position: relative;
  margin-top: 15px;
}

img {
  position: absolute;
  margin: auto;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.legend {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 200px;
  z-index: 1;
}

.legendWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #f3f3f3;
  padding: 5px 15px;
}

.legendBody {
  margin-top: 3px;
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, rgb(0, 255, 0) 10%,
                                        rgb(173, 255, 47) 20%,
                                        rgb(255, 255, 0) 40%,
                                        rgb(255, 165, 0) 60%,
                                        rgb(234, 72, 58) 80%);
}

.legendHead {
  font-size: 18px;
  text-align: center;
}

.legendFooter {
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
}
</style>
