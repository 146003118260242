import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/api/api';
import formatNumber from "@/scripts/formatNumber";
import convertStringToBool from "@/scripts/convertStringToBool";

Vue.use(Vuex);

const STANDARD_RADIUS = 1000;
const INITIAL_RADIUS = 18;
const STEP_RADIUS = 7;
const NUMBER_RECALCULATIONS_FOR_POPUP = 4;

export default {
    namespaced: true,

    state: {
        cities: [],
        selectedCityId: -1,

        address: '',

        mapCoordinates: [],
        currentMapZoom: -1,
        minMapZoom: -1,
        pointRadius: INITIAL_RADIUS,
        intensityMidpoint: 0,
        stepIntensityMidpoint: 0,
        radius: STANDARD_RADIUS,
        points: [],

        residents: 0,
        demandFactor: 0.5,
        isPreloadCalculating: false,

        isPreload: false,
        isPermitToRequest: true,

        isOpenPopup: false,
        numberRecalculations: Number(localStorage.getItem('numberRecalculations')),
        transformOriginY: 0,
        hasOpenPopup: convertStringToBool(localStorage.getItem('hasOpenPopup')),

        radioValue: 'inputAddress',

        company: [],
        selectedSubSectionId: [],

        currentTab: 'map',
    },

    getters: {
        getBigCities(state) {
            return state.cities.filter(city => city.name === 'Москва' || city.name === 'Санкт-Петербург');
        },

        getCommonCities(state) {
            return state.cities.filter(city => city.name !== 'Москва' && city.name !== 'Санкт-Петербург');
        },

        getIsAddress(state) {
            return state.address !== '';
        },

        getIsDisabledAddressInput(state) {
            return state.radioValue !== 'inputAddress';
        },

        cityName(state) {
            const cityIndex = state.cities.findIndex(item => item.id === state.selectedCityId);
            return cityIndex !== -1 ? state.cities[cityIndex].name : 'Москва';
        },

        getHeatMapViewGroup(state) {
            const cityIndex = state.cities.findIndex(item => item.id === state.selectedCityId);
            return state.cities[cityIndex].heatMapViewGroup;
        },

        getResidents(state) {
            return formatNumber(state.residents);
        },

        getDensity(state) {
            const density = Math.round((state.residents / (Math.PI * ((state.radius / 1000) ** 2))));
            return formatNumber(density);
        },

        getTraffic(state) {
            const potentialClients = Math.trunc((state.residents / 100) * state.demandFactor);
            return formatNumber(potentialClients);
        },

        getDemandFactor(state) {
            return state.demandFactor;
        },

        hideField(state) {
            return !state.hasOpenPopup && state.numberRecalculations === NUMBER_RECALCULATIONS_FOR_POPUP;
        },

        getAddress(state) {
          return state.address.trim();
        },

        isEmptyAddress(state, getters) {
            return getters.getAddress === '';
        }
    },

    mutations: {
        setRadioValue(state, val) {
            state.radioValue = val;
        },

        increasePointRadius(state) {
            state.pointRadius += STEP_RADIUS;
        },

        increaseIntensityMidpoint(state) {
            state.intensityMidpoint += state.stepIntensityMidpoint;
        },

        increaseZoom(state) {
            state.currentMapZoom += 1;
        },

        decreaseZoom(state) {
            state.currentMapZoom -= 1;
        },

        decreasePointRadius(state) {
            state.pointRadius -= STEP_RADIUS;
        },

        decreaseIntensityMidpoint(state) {
            state.intensityMidpoint -= state.stepIntensityMidpoint;
        },

        setInitialValuesMap(state, heatMapViewGroup) {
            switch (heatMapViewGroup) {
                case 0:
                    state.minMapZoom = 12;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS;
                    state.intensityMidpoint = 0.04;
                    state.stepIntensityMidpoint = 0.02;
                    break;
                case 1:
                    state.minMapZoom = 12;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS;
                    state.intensityMidpoint = 0.0075;
                    state.stepIntensityMidpoint = 0.002;
                    break;
                case 2:
                    state.minMapZoom = 12;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS;
                    state.intensityMidpoint = 0.0005;
                    state.stepIntensityMidpoint = 0.00025;
                    break;
                case 3:
                    state.minMapZoom = 13;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS + STEP_RADIUS;
                    state.intensityMidpoint = 0.0008;
                    state.stepIntensityMidpoint = 0.00045;
                    break;
                case 4:
                    state.minMapZoom = 14;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS + STEP_RADIUS * 2;
                    state.intensityMidpoint = 0.0014;
                    break;
                case 5:
                    state.minMapZoom = 14;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS + STEP_RADIUS * 2;
                    state.intensityMidpoint = 0.07;
                    break;
                case 6:
                    state.minMapZoom = 14;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS + STEP_RADIUS * 2;
                    state.intensityMidpoint = 0.001;
                    break;
                case 7:
                    state.minMapZoom = 13;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS + STEP_RADIUS;
                    state.intensityMidpoint = 0.0006;
                    state.stepIntensityMidpoint = 0.00045;
                    break;
                case 8:
                    state.minMapZoom = 12;
                    state.currentMapZoom = state.minMapZoom;
                    state.pointRadius = INITIAL_RADIUS;
                    state.intensityMidpoint = 0.00035;
                    state.stepIntensityMidpoint = 0.00025;
                    break;
            }

            // города-исключения
            if (state.selectedCityId === 1117) { // Ярославль
                state.intensityMidpoint = 0.00065;
                state.stepIntensityMidpoint = 0.00035;
            }

            if (state.selectedCityId === 26) { // Архангельск
                state.intensityMidpoint = 0.0012;
                state.stepIntensityMidpoint = 0.00045;
            }

            if (state.selectedCityId === 594) { // Мурманск
                state.intensityMidpoint = 0.1;
                state.stepIntensityMidpoint = 0.05;
            }

            if (state.selectedCityId === 431) { // Симферополь
                state.intensityMidpoint = 0.02;
                state.stepIntensityMidpoint = 0.015;
            }

            if (state.selectedCityId === 871) { // Владикавказ
                state.intensityMidpoint = 0.045;
                state.stepIntensityMidpoint = 0.025;
            }
        },

        setPreloadCalculating(state, isPreload) {
            state.isPreloadCalculating = isPreload;
        },

        changeDemandFactor(state, demandFactor) {
            if (demandFactor > 50 || demandFactor < 0) {
                state.demandFactor = 0.5;
            } else {
                state.demandFactor = demandFactor;
            }
        },

        increaseNumberRecalculations(state) {
            if (state.numberRecalculations !== NUMBER_RECALCULATIONS_FOR_POPUP) {
                state.numberRecalculations += 1;
                localStorage.setItem('numberRecalculations', String(state.numberRecalculations));
            }
        },

        changeHasOpenPopup(state, hasOpenPopup) {
            state.hasOpenPopup = hasOpenPopup;
            localStorage.setItem('hasOpenPopup', String(Number(hasOpenPopup)));
        },

        changeCities(state, cities) {
            for (let i = 0; i < cities.length; i++) {
                state.cities.push({
                    name: cities[i].name,
                    id: Number(cities[i].id),
                    cityCenterCoordinates: [Number(cities[i].lat), Number(cities[i].lon)],
                    heatMapViewGroup: Number(cities[i].heat_map_view_group),
                });
            }
        },

        changePoints(state, points) {
            state.points = points;
        },

        setSelectedCityId(state, id) {
            state.selectedCityId = id;
        },

        setMapCoordinates(state) {
            const cityIndex = state.cities.findIndex(
                item => item.id === state.selectedCityId
            );

            state.mapCoordinates = state.cities[cityIndex].cityCenterCoordinates;
        },

        setZoom(state, zoom) {
            state.baseZoom = zoom;
        },

        changeCoordinates(state, coordinates) {
            state.mapCoordinates = coordinates;
        },

        changeRadius(state, radius) {
            state.radius = radius;
        },

        changeResidents(state, residents) {
            let residentFromDb = 0;

            for (let i = 0; i < residents.length; i++) {
                residentFromDb += Number(residents[i].number_of_residents);
            }

            state.residents = residentFromDb;
        },

        showLoader(state) {
            state.isPreload = true;
        },

        hideLoader(state) {
            state.isPreload = false;
        },

        changeCompany(state, company) {
            state.company = company;
        },

        resetResults(state) {
            state.residents = 0;
            state.radius = 1000;
            state.selectedSubSectionId = [];
        },

        changeAddress(state, address) {
            state.address = address;
        },

        setSelectedSubSectionId(state, selectedSubSectionId) {
            state.selectedSubSectionId = selectedSubSectionId;
        },

        requestPermissionChange(state, val) {
            state.isPermitToRequest = val;
        },

        setCurrentTab(state, tab) {
            state.currentTab = tab;
        },
    },

    actions: {
        handlerDemandFactor(context, demandFactor) {
            context.commit('changeDemandFactor', demandFactor);
        },

        changeSelectedRadio(context, radioVal) {
            context.commit('setRadioValue', radioVal);
            context.commit('changeAddress', '');
        },

        async changeRadius(context, radius) {
            if (radius > 6000 || radius < 100) {
                context.commit('changeRadius', STANDARD_RADIUS);
                return;
            }
            if (context.state.address !== '') {
                context.commit('changeRadius', radius);
                context.commit('setPreloadCalculating', true);
                await context.dispatch('calculate');
                context.commit('setPreloadCalculating', false);
            }
        },

        async setCities(context) {
            const cities = await api.fetchCities();
            context.commit("changeCities", cities);
        },

        async setUserCity(context) {
            const city = await api.fetchUserCity();
            context.commit('setSelectedCityId', city.id);
            context.commit('setInitialValuesMap', context.getters.getHeatMapViewGroup);
        },

        async setPoints(context) {
            context.commit("showLoader");
            const points = await api.fetchPoints(context.state.selectedCityId);
            context.commit("changePoints", points);
            context.commit("hideLoader");
        },

        async calculate(context) {
            const residents = await api.calculate(context.state.radius, context.state.mapCoordinates);
            context.commit("changeResidents", residents);
            context.commit('increaseNumberRecalculations');
        },

        async setCoordinates(context, address) {
            const coords = await api.fetchCoordinates(address);

            context.commit('changeAddress', address);
            context.commit('changeCoordinates', coords);
        },

        async setCompany(context) {
            context.commit("showLoader");

            if (context.state.isPermitToRequest) {
                context.commit("requestPermissionChange", false);
                const company = await api.fetchCompany(context.state.radius, context.state.mapCoordinates,
                    context.state.selectedCityId, context.state.selectedSubSectionId);

                context.commit("changeCompany", company);
                context.commit("requestPermissionChange", true);
                context.commit("hideLoader");

                if (context.state.requestNeeded) {
                    context.state.requestNeeded = false;
                    await context.dispatch("setCompany");
                }
            } else {
                context.state.requestNeeded = true;
            }
        },

//      Сделан отдельны метод по причине того, что много чего опирается на готовность карты к работе.
        async initializationMap(context) {
            await context.dispatch('setCities');
            await context.dispatch('setUserCity');
            context.commit('setMapCoordinates');
            await context.dispatch('setPoints');
        },
    },
};
