// todo: вынести в репозиторий reusable-helpers

class ValidSearchHelper {
    UNACCEPTABLE_SEARCH_WORDS = [];

    constructor(words) {
        this.UNACCEPTABLE_SEARCH_WORDS = words;
    }

    checkWordAccept (word) {
        return this.UNACCEPTABLE_SEARCH_WORDS
            .filter(item => item.includes(word)).length === 0;
    }

    checkWordSize (word, minLength) {
        return word.length >= minLength;
    }

    checkWordValidity (word, minLength) {
        return this.checkWordAccept(word) && this.checkWordSize(word, minLength);
    }
}

export default ValidSearchHelper;
