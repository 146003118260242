import Vue from "vue";
import App from "./components/App.vue";
import Vuex from "vuex";
import store from "./store/store";
import VModal from "vue-js-modal";
import initializeStorage from "@/scripts/initializeStorage";

Vue.use(Vuex);
Vue.use(VModal, { dialog: true });

Vue.config.productionTip = false;

initializeStorage();

new Vue({
    render: h => h(App),
    store
}).$mount("#app");
