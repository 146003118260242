<template>
  <div class="suggest">
    <input :disabled="getIsDisabledAddressInput" type="text" ref="suggest"
           placeholder="Марксистская улица, 5" :class="['suggestInput', { 'invalid': !isAddressValid }]"
           @click="setAddressValid(true)"
           :value="address" @input="input"
           @keydown.up="moveUp" @keydown.down="moveDown"
           @keydown.enter="handleEnter"
           @keydown.esc="closeSuggestBody"
           title="Начните вводить адрес и выберите подходящий дом в выпадающем списке"
    />

    <ul :class="['suggestBody', { 'open': checkOpenSuggestBody }]">
      <li v-for="(item, i) in mock" :key="i" class="suggestItemWrapper">
        <button type="button" :class="['suggestItem', { 'active': i === selectedIndex }]"
                @click="selectAddress(item)"
        >
          {{ item }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import api from "@/api/api";

const NOT_SELECTED_ITEM = -1;

export default {
  name: "SuggestInput",

  data() {
    return {
      isAddressValid: true,
      isOpenSuggest: false,
      selectedIndex: NOT_SELECTED_ITEM,
      mock: []
    }
  },

  computed: {
    ...mapState('mapStore', [
       'address'
    ]),

    ...mapGetters('mapStore', [
        'getIsDisabledAddressInput',
        'cityName',
        'isEmptyAddress',
        'getAddress'
    ]),

    checkOpenSuggestBody() {
      return this.isOpenSuggest && this.mock.length !== 0 && !this.isEmptyAddress;
    },
  },

  methods: {
    ...mapMutations('mapStore', [
        'setZoom',
        'changeAddress'
    ]),

    ...mapActions('mapStore', [
        'setCoordinates',
        'calculate'
    ]),

    moveUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
    },

    moveDown() {
      if (this.selectedIndex < this.mock.length - 1) {
        this.selectedIndex++;
      }
    },

    async handleEnter() {
      if (this.selectedIndex !== -1) {
        const address = this.mock[this.selectedIndex];

        await this.selectAddress(address);
      }
    },

    setAddressValid(choice) {
      this.isAddressValid = choice;

      this.openSuggestBody();
    },

    openSuggestBody() {
      this.isOpenSuggest = true;
    },

    closeSuggestBody() {
      this.isOpenSuggest = false;

      this.unselectedItem();
    },

    unselectedItem() {
      this.selectedIndex = NOT_SELECTED_ITEM;
    },

    handleClickOutside(e) {
      if (this.$refs.suggest && !this.$refs.suggest.contains(e.target)) {
        this.closeSuggestBody();
      }
    },

    async selectAddress(address) {
      try {
        this.setZoom(14);

        await this.setCoordinates(address.trim());
        await this.calculate();
      } catch (e) {
        let message;

        switch (e.response.status) {
          case 429:
            message = 'К сожалению, сервер перегружен. Пожалуйста, воспользуйтесь сайтом позже';
            break;
          default:
            message = 'Непредвиденная ошибка. Попробуйте ввести другой адрес';
            break;
        }

        this.$modal.show('dialog', {
          title: 'Ошибка',
          text: message,
        });
      }

      this.changeAddress(address);
      this.closeSuggestBody();
    },

    async input(e) {
      this.openSuggestBody();

      this.changeAddress(e.target.value);

      await this.fetchSuggest();
    },

    async fetchSuggest() {
      if (this.isEmptyAddress) {
        return
      }

      const query = `${this.cityName} ${this.getAddress}`;

      this.mock = await api.fetchSuggest(query);
    }
  },

  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },

  beforeMount() {
    window.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>

.suggest {
  width: 350px;
  position: relative;
}

.suggestInput {
  height: 30px;
  font-size: 16px;
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
}

.suggestBody {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  border: 1px solid #ccc;
  border-top: none;
  background-color: white;
  border-radius: 0 0 10px 10px;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  z-index: 10;
}

.suggestBody.open {
  opacity: 1;
  visibility: visible;
}

.suggestItem {
  text-align: start;
  padding: 5px;
  width: 100%;
  background-color: transparent;
}

.suggestItem:focus {
  outline: none;
  background-color: #dbd7d9;
}

.suggestItem:hover {
  background-color: #dbd7d9;
}

.invalid {
  border-color: red;
}

.active {
  background-color: #dbd7d9;
}
</style>
