<template>
  <modal name="feedback" :adaptive="true"
         styles="padding: 15px"
         :width="700" height="auto" :scrollable="true" :classes="'feedback'"
         @closed="close">

    <button type="button" class="close" @click="$modal.hide('feedback')">&#x2715;</button>

    <h2 class="title">Дарим 500 руб. за опрос</h2>
    <p class="description">
      Предлагаем оставить номер телефона и пройти небольшой опрос. Каждому участнику дарим 500 руб. на бонусный счет в
      <a href="https://export-base.ru/" class="link" target="_blank">ExportBase</a>.
    </p>
    <form @submit.prevent="submit" class="formFeedback">
      <p class="formItem">
        <label class="label">Телефон (нужно заполнить)</label>
        <imask-input :class="['input', {'error': !isTruePhone}]" type="text"
                     :lazy="false"
                     v-model="phone"
                     :mask="'+7 (000) 000-00-00'"
                     placeholderChar="_"/>
      </p>
      <p class="formItem">
        <label class="label">Email</label>
        <input :class="['input', {'error': !isTrueEmail}]" type="text" placeholder="ivanov@mail.ru" v-model="email">
      </p>
      <div class="formItem">
        <p class="titleTimeWrapper">Удобное время для звонка</p>
        <div class="timeWrapper">
          <p class="timeElement">
            <label class="timeLabel">С</label>
            <input :class="['input', 'inputTime', {'error': !isTrueFirstTime}]" type="time" v-model="firstTime">
          </p>
          <p class="timeElement">
            <label class="timeLabel">До</label>
            <input :class="['input', 'inputTime', {'error': !isTrueSecondTime}]" type="time" v-model="secondTime">
          </p>
        </div>
      </div>
      <div :class="['formItem', 'checkboxWrapper', {'errorText': !checked}]">
        <input class="checkbox" type="checkbox" :checked="checked" v-model="checked">
        <div class="createdCheckbox" @click="toggleChecked"></div>
        <label class="checkboxTitle">
          <span>
            Даю согласие на обработку моего телефона и e-mail в соответствии с
            <a href="https://export-base.ru/privacy_policy" class="link" target="_blank">политикой конфиденциальности ExportBase</a>
          </span>
        </label>
      </div>
      <p class="comment formItem">
        Вежливый оператор перезвонит в указанное время и задаст несколько вопросов о вашем опыте работы с сайтом.
        Весь разговор займет не больше 5 минут.
      </p>
      <div class="buttonWrapper">
        <button class="button submit">Отправить</button>
        <button type="button" class="button common" @click="$modal.hide('feedback')">Отказаться</button>
      </div>
    </form>
  </modal>
</template>

<script>
import {IMaskComponent} from 'vue-imask';
import api from "@/api/api";

const REGULAR_PHONE = /\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/;
const REGULAR_EMAIL = /^[\w.-]{2,}@[a-z]{2,}.[a-z]{2,}$/;
const REGULAR_TIME = /\d{2}:\d{2}/;

export default {
  name: "Feedback",
  components: {
    'imask-input': IMaskComponent
  },
  data() {
    return {
      phone: '',
      isTruePhone: true,
      email: '',
      isTrueEmail: true,
      checked: true,
      firstTime: '',
      isTrueFirstTime: true,
      secondTime: '',
      isTrueSecondTime: true
    };
  },

  methods: {
    async submit() {
      if (!this.checkFields()) {
        return
      }

      try {
        await api.fetchFeedback(
            this.phone,
            this.email,
            this.firstTime,
            this.secondTime
        );

        this.$modal.show('dialog', {
          text: 'Благодарим за оставленное вами сообщение.',
        });

      } catch (e) {
        this.$modal.show('dialog', {
          text: 'К сожалению, произошла ошибка. Сообщение не отправлено',
        });
      } finally {
        this.$modal.hide('feedback');
      }
    },

    checkFields() {
      this.isTruePhone = this.checkField(this.phone, REGULAR_PHONE);

      this.isTrueEmail = this.checkField(this.email, REGULAR_EMAIL, false);

      this.isTrueFirstTime = this.checkField(this.firstTime, REGULAR_TIME);

      this.isTrueSecondTime = this.checkField(this.secondTime, REGULAR_TIME);

      return this.isTruePhone && this.isTrueEmail && this.isTrueSecondTime && this.isTrueSecondTime && this.checked;
    },

    checkField(val, regular, required = true) {
      if (required) {
        return val !== '' && regular.test(val);
      } else if (val !== '') {
        return regular.test(val);
      } else {
        return true;
      }
    },

    toggleChecked() {
      this.checked = !this.checked;
    },

    close() {
      this.isTruePhone = true;
      this.isTrueEmail = true;
      this.isTrueFirstTime = true;
      this.isTrueSecondTime = true;
      this.checked = true;
    }
  }
}
</script>

<style scoped>
.feedback {
  position: relative;
}

.buttonWrapper {
  display: flex;
  justify-content: right;
}

.title {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 15px;
}

.description {
  margin-bottom: 20px;
}

.formItem {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 2px;
  margin-left: 10px;
}

.timeLabel {
  margin-right: 10px;
  margin-left: 5px;
}

.titleTimeWrapper {
  margin-bottom: 5px;
  margin-left: 10px;
}

.input {
  width: 100%;
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.inputTime {
  width: 135px;
}

.input:focus {
  box-shadow: 0 0 0 2px rgba(165, 199, 9, 0.5),
  0 2px 4px rgba(165, 199, 9, 0.1),
  0 4px 8px rgba(165, 199, 9, 0.1),
  0 8px 16px rgba(165, 199, 9, 0.1);
}

.timeWrapper {
  width: 50%;
  display: flex;
}

.timeElement {
  width: 170px;
  display: flex;
  align-items: center;
}

.button {
  padding: 7px;
  border-radius: 5px;
  justify-self: right;
  color: black;
}

.common {
  background-color: #e8e8e8;
}

.submit {
  background: linear-gradient(to bottom, #ffe900, #ffcf01);
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.close {
  background-color: white;
  font-size: 25px;
  cursor: pointer;
  padding: 2px;
  position: absolute;
  top: 5px;
  right: 15px;
}

.link {
  color: #5050fd;
}

.link:hover {
  text-decoration: underline;
}

.comment {
  margin-top: 20px;
}

.checkbox {
  display: none;
}

.createdCheckbox {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 25px;
}

.createdCheckbox::before {
  content: "";
  align-self: center;
  flex: 0 0 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox:checked + .createdCheckbox::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 3px;
  border-radius: 3px;
  background-color: #8ba608;
  cursor: pointer;
}

.error {
  border-color: red;
}

.input:focus.error {
  border-color: #ccc;
}

.errorText {
  text-decoration: red underline;
}
</style>