<template>
  <NewFooter>
    <h2><b>ДОПОЛНИТЕЛЬНО</b></h2>
    <a href="#faq" @click="scrollToFaq" title="F.A.Q." class="link">
      <span class="span_dashed">F.A.Q. Как это работает?</span>
    </a>
    <button @click="$modal.show('requisites')" title="Реквизиты" class="buttonLink">
      <span class="span_dashed">Реквизиты компании</span>
    </button>
    <a href="https://export-base.ru/add_company" target="_blank" title="добавление базы"
       class="footer_new_addNewCompany"><b>Добавить вашу компанию</b>
    </a>
    <a href="https://export-base.ru/catalog_baz" target="_blank" title="Магазин баз"
       class="footer_new_addNewCompany"><b>Магазин баз по категориям</b>
    </a>
  </NewFooter>
</template>

<script>

import NewFooter from 'footer';
import {ScrollToFaq} from '@/mixins/scrollToFaq';

export default {
  name: 'Footer',
  components: {
    NewFooter,
  },

  mixins: [ScrollToFaq],
};

</script>

<style scoped>
a.footer_new_addNewCompany {
  margin-top: 1.25em!important;
  margin-bottom: 0!important;
}

.span_dashed {
  border-bottom: 1px dashed white;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.buttonLink {
  background-color: #353535;
  text-align: left;
}
</style>
