<template>
  <section class="head">
    <h1 class="title">Тепловая карта плотности населения России</h1>
    <p>
      Показывает распределение жителей на территории городов<br>
      Используйте для оценки потенциала точки ритейла
    </p>
  </section>
</template>

<script>
export default {
  name: 'HeadBlock'
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 5px 40px 5px;
}

.title {
  background: url("./img/stripedBackground.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-style: italic;
  padding: 10px 40px 30px 6px;
  font-size: 20px;
}

.description {
  margin-right: 5px;
}
</style>
