<template>
  <div class="mainMenu">
    <div class="headMenu">
      <div id="faq" class="tabContainer">
        <div v-for="(value, name) in tabs" :key="value"
             :class="['tabButton', { tabButtonActive: currentTab !== name }]" @click="setCurrentTab(name)">
          <span :class="{ tabButtonContentActive: currentTab !== name }">{{ value }}</span>
        </div>
      </div>
      <a href="https://export-base.ru/" class="linkMainSite" target="_blank"><span class="textLink">Скачать базу предприятий</span></a>
    </div>
    <MainMenuContent v-show="currentTab === 'map'"/>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import MainMenuContent from '@/components/MainMenu/MainMenuContent';

export default {
  name: 'MainMenu',
  components: {
    MainMenuContent,
  },

  computed: {
    ...mapState('mapStore', ['currentTab']),
  },

  data() {
    return {
      tabs: {
        map: 'Тепловая карта',
        info: 'Как это работает?',
      },
    };
  },

  methods: {
    ...mapMutations('mapStore', ['setCurrentTab']),
  },
};
</script>

<style scoped>
.mainMenu {
  display: flex;
  flex-direction: column;
}

.headMenu {
  display: flex;
  justify-content: space-between;
}

.tabContainer {
  display: flex;
}

.tabButton {
  background-color: #e5e3e3;
  border-radius: 4px 4px 0 0;
  padding: 15px 25px;
  cursor: pointer;
}

.tabButtonActive {
  opacity: 0.5;
}

.tabButtonContentActive {
  border-bottom: 1px dashed black;
}

.linkMainSite {
  color: black;
  padding: 15px 5px;
  font-weight: bold;
}

.textLink {
  border-bottom: solid 1px;
}

.textLink:hover {
  border-bottom: none;
}
</style>
