<template>
    <div :data-tooltip="text" :class="['popup', 'borderTriangle', side]">
      <svg xmlns="http://www.w3.org/2000/svg" class="infoImg bi bi-info-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
      </svg>
    </div>
</template>

<script>
export default {
  name: "Hint",

  props: {
    side: String,
    text: String
  }
}
</script>

<style scoped>
.infoImg {
  margin-left: 5px;
  height: 30px;
  width: 30px;
  padding: 4px;
  cursor: pointer;
  fill: #2a2a2a;
}

.infoImg:hover {
  fill: #707070;
}

.popup {
  position: relative;
}

.popup::after {
  content: attr(data-tooltip);
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 280px;
  top: 40px;
  background-color: white;
  padding: 10px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all ease 0.2s;
}

.popup:hover::after {
  opacity: 1;
  visibility: visible;
}

.borderTriangle::before {
  content: '';
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 0;
  top: 10px;
  left: 10px;
  pointer-events: none;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all ease 0.4s;
}

.borderTriangle:hover::before {
  visibility: visible;
  opacity: 1;
  top: 20px;
}

.down-right.popup::after {
  left: -5px;
}

.down.popup::after {
  left: -125px;
}

.down-left.popup::after {
  left: -235px;
}
</style>