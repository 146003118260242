import Vue from 'vue';
import Vuex from 'vuex';
import okvedStore from './modules/okvedStore/okvedDataStore';
import mapStore from './modules/mapStore/mapStore';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        okvedStore,
        mapStore,
    },
});
