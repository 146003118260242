<template>
  <main class="mainBlock">
    <HeadBlock/>
    <div class="block">
      <LeftMenu/>
      <div class="mainBlockColumn">
        <MainMenu class="highestPriority"/>
        <Map :mapCoordinates="mapCoordinates"
             :minMapZoom="minMapZoom"
             :currentMapZoom="currentMapZoom"
             :pointRadius="pointRadius"
             :intensityMidpoint="intensityMidpoint"
             :radioValue="radioValue"
             :isPreload="isPreload"
             :points="points"
             :radius="radius"
             :company="company"
             v-show="currentTab === 'map'"
             @mapIsReady="initializationMap"/>
        <Info/>
        <a href="mailto:info@export-base.ru" target="_blank" class="banner_link">
          <img src="./img/banner_feedback.png" width="830" height="268" class="banner" alt="">
        </a>
        <AboutHeatMap :class="{'priority' : this.currentTab !== 'map'}"/>
      </div>
    </div>
  </main>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import LeftMenu from '../LeftMenu/LeftMenu';
import AboutHeatMap from "@/components/AboutHeatMap/AboutHeatMap.vue";
import Map from '../Map/Map';
import MainMenu from '../MainMenu/MainMenu';
import HeadBlock from '../HeadBlock/HeadBlock';
import Info from '../Info/Info';

export default {
  name: 'MainBlock',
  components: {
    Info,
    LeftMenu,
    Map,
    MainMenu,
    HeadBlock,
    AboutHeatMap
  },

  computed: {
    ...mapState(
        'mapStore', {
          isPreload: 'isPreload',
          mapCoordinates: 'mapCoordinates',
          currentMapZoom: 'currentMapZoom',
          minMapZoom: 'minMapZoom',
          points: 'points',
          radius: 'radius',
          company: 'company',
          currentTab: 'currentTab',
          pointRadius: 'pointRadius',
          intensityMidpoint: 'intensityMidpoint',
          radioValue: 'radioValue'
        }
    ),
  },

  methods: {
    ...mapActions('mapStore', ['initializationMap'])
  },
};
</script>

<style scoped>
.mainBlock {
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  max-width: 1160px;
  margin: auto;
}

.mainBlockColumn {
  display: flex;
  flex-direction: column;
  width: 73%;
}

.highestPriority {
  order: -2;
}

.priority {
  order: -1;
}

.banner_link {
  margin-top: 15px;
}
.banner {
  width: 100%;
  height: auto;
}

@media (max-width: 800px) {
  .mainBlockColumn {
    width: 100%;
  }
}

.block {
  display: flex;
  margin: 0 5px;
}
</style>
