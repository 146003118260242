<template>
  <div id="app">
    <v-dialog style="position: fixed; top: 0; left: 0; z-index: 1000" />
    <Header/>
    <MainBlock/>
    <Footer/>
  </div>
</template>

<script>
import Header from './Header/Header';
import MainBlock from './MainBlock/MainBlock';
import Footer from './Footer/Footer';

export default {
  name: 'App',
  components: {
    Header,
    MainBlock,
    Footer,
  },
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

input, button, textarea {
  font-family: inherit;
}

a, a:visited, a:hover {
  text-decoration: none;
  color: inherit;
}

button {
  color: inherit;
  font-size: inherit;
  cursor: pointer;
}

.ymaps-2-1-79-map {
  width: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  min-width: 1000px;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
}

input {
  outline: none;
}

ul {
  list-style: none;
}
</style>
